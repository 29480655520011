import LooseObject from '@/models/Objects/LooseObject';
import FormModel from '@/models/Objects/FormModel';
import Message from '@/models/Components/MessageBubble/Message';
import MessageBubbleModel from '@/models/Components/MessageBubble/MessageBubbleModel';
import moment from 'moment';

export function extractMessageHistory(formHistory: FormModel[]): MessageBubbleModel[] {
    const messageBubbles: MessageBubbleModel[] = [];
    if (formHistory !== undefined) {
        let id = 0;
        formHistory.map((form) => {
            id += 1;
            let messageBubble: MessageBubbleModel = {
                userMessage: false,
                elementId: `message-bubble-${id}`,
                formName: form.name,
                workflowName: form.workflow,
                messages: [{
                    elementId: 'form_' + id.toString(),
                    value: form.text,
                }],
                responseDate: form.responseDate,
                requestDate: form.requestDate,
                disabled: false,
            };
            messageBubbles.push(messageBubble);
            id += 1;
            const previousMessages: Message[] = [];
            let previousFormData: LooseObject = {};
            form.formFields.map((formField) => {
                const value = formField.attributes?.value;
                if (value !== undefined && value != null && formField.type !== 'input_hidden') {
                    const props = formField.attributes ?? {};
                    props.options = formField.options;
                    previousMessages.push({
                        elementId: formField.name,
                        value,
                        displayValue: getFormattedString(props, value),
                        label: props.ariaLabel ?? formField.text,
                    });

                    previousFormData = {
                        ...previousFormData,
                        ...JSON.parse(`{"${formField.name}": "${value}"}`),
                    };
                }
            });
            if (form.submittedMessageText !== undefined) {
                const value = form.submittedMessageText;
                previousMessages.push({
                    elementId: 'submit_' + id.toString(),
                    value,
                    label: ' ',
                });

                previousFormData = {
                    ...previousFormData,
                    ...JSON.parse(`{"submit": "${value}"}`),
                };
            }
            if (previousMessages.length > 0) {
                messageBubble = {
                    userMessage: true,
                    elementId: `message-bubble-${id}`,
                    formName: form.name,
                    workflowName: form.workflow,
                    messages: previousMessages,
                    formData: previousFormData,
                    responseDate: form.responseDate,
                    requestDate: form.requestDate,
                    disabled: false,
                };
                messageBubbles.push(messageBubble);
            }
        });
    }
    return messageBubbles;
}


export function getFormattedString(props: LooseObject, value: any): string {
    let formattedString = String(value);
    if (value !== undefined) {
        if (props !== undefined) {
            if (props.pattern !== undefined && props.pattern !== '')  {
                const regex = new RegExp(props.pattern, 'g');
                formattedString = value.toString().replace(regex, '');
            }
            if (props.options !== undefined) {
                const options: LooseObject[] = props.options.filter(
                    (option: LooseObject) => String(option.value) === String(value))
                    .map((option: LooseObject) => option);
                if (options.length > 0) {
                    formattedString = String(options[0].title ?? options[0].label);
                }
            } else if (props.currency !== undefined) {
                formattedString = applyCurrencyFormat(formattedString, props.currency, props.locale, props.decimals);
            } else if (props.unit !== undefined) {
                formattedString = applyNumberFormat(formattedString, props.unit, props.locale, props.decimals);
            } else if (props.format !== undefined) {
                if (isDateFormat(props.format)) {
                    formattedString = applyDateFormat(props.format, formattedString);
                }
            }
        }
        formattedString = formattedString.split('\n').join('<br/>');
    } else {
        return '';
    }
    return formattedString;
}

function isNullOrUndefined(value: any) {
    if (value === undefined) {
        return true;
    }
    if (value == null) {
        return true;
    }
    return false;
}

function applyCurrencyFormat(value: string, currency: string, locale: string, decimals: string): string {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(parseFloat(value));
}

function applyNumberFormat(value: string, unit: string, locale: string, decimals: string): string {
    const formatter = new Intl.NumberFormat(locale,
        /* {
        // @ts-ignore
        style: 'unit',
        // @ts-ignore
        unit,
    }*/
    );
    return formatter.format(parseFloat(value));
}

function applyDateFormat(format: string, value: string): string {
    return moment(value, 'YYYY-MM-DD').format(format);
}

function isDateFormat(format: string): boolean {
    return (format === 'DD-MM-YYYY' || format === 'YYYY-MM-DD' || format === 'MM-DD-YYYY');
}
