export function getCookie(name: string) {
    const match = document.cookie.match(new RegExp(name + '=([^;]+)'));
    return match ? match[1] : undefined;
}

export function createCookie(name: string, value?: string, seconds?: number) {
    if (!value || value === '' || value === 'null') {
        value = '';
        seconds = -1;
    }
    let maxAge = '';
    if (seconds) {
        const date = new Date();
        date.setTime(date.getTime() + (seconds * 1000));
        maxAge = '; Max-Age=' + seconds.toString();
    }
    document.cookie = name + '=' + value + maxAge + '; Path=/; SameSite=strict; Secure';
}
